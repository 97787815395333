<template lang="pug">
span.widget-template-card-close-icon
  svg.close-icon(
    :width="width"
    :height="height"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  )
    path(
      d="M8.3334 9.44438L0.555784 1.66665C0.248498 1.35937 0.248498 0.862841 0.555784 0.555551C0.863069 0.248262 1.35959 0.248262 1.66687 0.555551L9.44449 8.33327C9.75178 8.64056 9.75178 9.13709 9.44449 9.44438C9.13721 9.75166 8.64069 9.75166 8.3334 9.44438Z"
      :fill="color"
    )
    path(
      d="M0.555745 8.33325L8.33337 0.555532C8.64065 0.248243 9.13717 0.248243 9.44446 0.555532C9.75174 0.862822 9.75174 1.35935 9.44446 1.66664L1.66683 9.44436C1.35955 9.75164 0.863031 9.75164 0.555745 9.44436C0.24846 9.13707 0.24846 8.64054 0.555745 8.33325Z"
      :fill="color"
    )
</template>

<script>
  export default {
    props: {
      width: {
        type: Number,
        default: 10,
      },
      height: {
        type: Number,
        default: 10,
      },
      color: {
        type: String,
        default: '#B9BEC6',
      },
    },
  };
</script>
