var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"variant-emphasized-table"},[_c('emphasized-om-table',{attrs:{"emphasizedItems":_vm.getChampion,"emphasizedColumns":_vm.emphasizedColumns,"emphasizedColumnClasses":_vm.emphasizedColumnClasses,"highlightedEmphasizedRows":_vm.getChampion.length === 1 && _vm.getChampion[0].status === 'inactive' ? [0] : [],"highlightedEmphasizedClass":'inactive',"items":_vm.getChallengerVariants,"columns":_vm.columns,"highlightedRows":_vm.highlightedRows,"highlightClass":'inactive',"selectable":false,"allItemsCount":_vm.variantCount - _vm.getChampion.length,"columnClasses":_vm.columnClasses,"loadingContent":_vm.loadingVariantsData,"pagination":_vm.pagination,"customPaginationControls":_vm.customPaginationControls},on:{"selectedIdsChange":function($event){return _vm.$emit('selectedIdsChange', $event)},"sortingChange":function($event){return _vm.$emit('sortingChange', $event)},"paginationChange":function($event){return _vm.$emit('paginationChange', $event)},"tableRowLeave":function($event){return _vm.closeKebabMenu($event)}},scopedSlots:_vm._u([{key:"emphasized-preview",fn:function(ref){
var rowData = ref.rowData;
return [_c('a',{staticClass:"brand-variant-name",class:{ 'cursor-default': !!rowData.row.isControlVariant },attrs:{"href":_vm.getEditorLink(rowData.row),"title":rowData.row.name}},[_c('variant-preview',{key:rowData.row.id,attrs:{"allowSsr":!!_vm.templateType,"dimensions":_vm.boxDimensions,"updatedAt":_vm.updatedAt,"variant":rowData.row,"templateName":_vm.templateName,"transparentOverlay":false,"templateType":_vm.templateType,"isControlVariant":rowData.row.isControlVariant,"ready":!!_vm.updatedAt},on:{"observable":function($event){return _vm.addObservable($event.$el)},"inited":_vm.updateDimensions,"click":function($event){return _vm.navToEditor(rowData.row)}}})],1)]}},{key:"emphasized-name",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex align-items-end"},[_c('a',{staticClass:"brand-variant-name",attrs:{"href":_vm.getEditorLink(rowData.row),"title":rowData.row.name},on:{"click":function($event){return _vm.navToEditor(rowData.row)}}},[_vm._v(_vm._s(rowData.row.name))])])]}},{key:"emphasized-status",fn:function(ref){
var rowData = ref.rowData;
return [_c('toggle-button',{ref:'toggle_' + rowData.row._id,staticClass:"mb-0",class:{ inactive: rowData.row.status === 'inactive' },attrs:{"value":rowData.row.status === 'active',"sync":true,"labels":{ checked: _vm.$t('active'), unchecked: _vm.$t('inactive') },"width":76,"height":24,"margin":1},on:{"change":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toggleVariantStatus(rowData.row._id, $event.value ? 'active' : 'inactive', $event.srcEvent)}}})]}},{key:"emphasized-impressions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',[_vm._v(_vm._s(_vm._f("thousandSep")(rowData.row.impressions)))])]}},{key:"emphasized-conversions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',[_vm._v(_vm._s(_vm._f("thousandSep")(rowData.row.conversions)))])]}},{key:"emphasized-conversionRate",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',[_vm._v(_vm._s(((_vm.getConversion(rowData.row.conversions, rowData.row.impressions)) + " %")))])]}},{key:"emphasized-settings",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.isNew)?_c('om-kebab-menu',{ref:("kebab_" + (rowData.row._id)),class:{ 'hide-delete': !_vm.showDeleteVariantMenu, 'delete-border-top': _vm.showDeleteVariantMenu },attrs:{"rowData":rowData},on:{"edit":function($event){return _vm.toEditor(rowData.row)},"duplicate":function($event){return _vm.copyVariant(rowData.row._id, rowData.index)},"preview":function($event){return _vm.previewCampaign(rowData.row)},"delete":function($event){return _vm.deleteVariant(rowData.row._id)},"rename":function($event){return _vm.showRenameModal(rowData.row._id, rowData.row.name, rowData.index)}}},[_c('template',{slot:"duplicate"},[_c('span',[_vm._v(_vm._s(_vm.$t('duplicate')))])]),_c('template',{slot:"edit"},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('template',{slot:"preview"},[_c('span',[_vm._v(_vm._s(_vm.$t('preview')))])]),_c('template',{slot:"rename"},[_c('span',[_vm._v(_vm._s(_vm.$t('rename')))])]),_c('template',{slot:"delete"},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],2):_vm._e()]}},{key:"preview",fn:function(ref){
var rowData = ref.rowData;
return [_c('a',{staticClass:"brand-variant-name",class:{ 'cursor-default': !!rowData.row.isControlVariant },attrs:{"href":_vm.getEditorLink(rowData.row),"title":rowData.row.name},on:{"click":function($event){return _vm.navToEditor(rowData.row)}}},[_c('variant-preview',{key:rowData.row.id,attrs:{"allowSsr":true,"dimensions":_vm.boxDimensions,"variant":rowData.row,"updatedAt":_vm.updatedAt,"templateName":_vm.templateName,"transparentOverlay":false,"templateType":_vm.templateType,"isControlVariant":rowData.row.isControlVariant,"ready":!!_vm.updatedAt},on:{"observable":function($event){return _vm.addObservable($event.$el)},"inited":_vm.updateDimensions,"click":function($event){return _vm.navToEditor(rowData.row)}}})],1)]}},{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex align-items-end brand-variant-name-holder"},[(!_vm.isControlVariant(rowData.row))?_c('a',{staticClass:"brand-variant-name",attrs:{"href":_vm.getEditorLink(rowData.row),"title":rowData.row.name},on:{"click":function($event){return _vm.navToEditor(rowData.row)}}},[_vm._v(_vm._s(rowData.row.name))]):_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"control-title pb-2 font-size-0--9375"},[_vm._v(_vm._s(_vm.$t('variantTable.controlTitle')))]),_c('div',{staticClass:"control-desc-wrapper"},[(!_vm.isDynamicContent)?_c('div',{staticClass:"control-desc text-truncate font-size-0--75"},[_vm._v(_vm._s(_vm.$t('variantTable.controlDesc')))]):_c('div',{staticClass:"control-desc text-truncate font-size-0--75"},[_vm._v(_vm._s(_vm.$t('variantTable.dynamicContentDesc')))])])])])]}},{key:"status",fn:function(ref){
var rowData = ref.rowData;
return [_c('toggle-button',{ref:'toggle_' + rowData.row._id,staticClass:"mb-0",class:{ inactive: rowData.row.status === 'inactive' },attrs:{"value":rowData.row.status === 'active',"sync":true,"labels":{ checked: _vm.$t('active'), unchecked: _vm.$t('inactive') },"width":76,"height":24,"margin":1},on:{"change":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toggleVariantStatus(rowData.row._id, $event.value ? 'active' : 'inactive', $event.srcEvent)}}})]}},{key:"impressions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{attrs:{"data-testid":"impressionCell"}},[_vm._v(_vm._s(_vm._f("thousandSep")(rowData.row.impressions)))])]}},{key:"conversions",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.loadingGoals)?_c('vue-skeleton-loader',{staticClass:"skeleton-goals",attrs:{"type":"rect","width":"50%","height":"24px","rounded":true}}):_c('div',{attrs:{"data-testid":"conversionCell"}},[_vm._v(_vm._s(_vm._f("thousandSep")(_vm.isControlVariant(rowData.row) && _vm.isDefaultGoal ? '-' : rowData.row.conversions)))])]}},{key:"conversionRate",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.loadingGoals)?_c('vue-skeleton-loader',{staticClass:"skeleton-goals",attrs:{"type":"rect","width":"50%","height":"24px","rounded":true}}):_c('div',{staticClass:"result d-flex align-items-center justify-content-end",class:{ 'result-has-child': _vm.getChampion.length },attrs:{"data-testid":"conversionRateCell"}},[_vm._v(_vm._s(_vm.getConversionForRow(rowData.row))),(_vm.getChampion.length && _vm.conversionComparedToChamp(rowData.row))?[_c('div',{staticClass:"result-box d-flex ml-2 font-weight-bold",class:_vm.conversionComparedToChamp(rowData.row) > 0 ? 'result-positive' : 'result-negative'},[_c('i',{staticClass:"fa",class:_vm.conversionComparedToChamp(rowData.row) > 0 ? 'fa-long-arrow-up' : 'fa-long-arrow-down',attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(Math.abs(_vm.conversionComparedToChamp(rowData.row)) + ' %'))])])]:_vm._e()],2)]}},{key:"confidence",fn:function(ref){
var rowData = ref.rowData;
return [(!rowData.row.confidence || (_vm.isControlVariant(rowData.row) && _vm.isDefaultGoal))?_c('div',[_vm._v("-")]):_c('div',[_c('div',{staticClass:"brand-variant-confidence-value"},[_c('div',[_vm._v(_vm._s(rowData.row.confidence.toFixed(0) + ' %'))])])])]}},{key:"settings",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.isControlVariant(rowData.row))?_c('div',{staticClass:"control-variant-delete"},[_c('om-button',{attrs:{"ghost":"","iconOnly":"","small":"","icon":"trash-alt"},on:{"click":function($event){return _vm.deleteControlVariant(rowData.row)}}})],1):(_vm.isNew)?[_c('om-kebab-menu',{ref:("kebab_" + (rowData.row._id)),class:{ 'hide-delete': !_vm.showDeleteVariantMenu, 'delete-border-top': _vm.showDeleteVariantMenu },attrs:{"rowData":rowData},on:{"edit":function($event){return _vm.toEditor(rowData.row)},"duplicate":function($event){return _vm.copyVariant(rowData.row._id, rowData.index)},"delete":function($event){return _vm.deleteVariant(rowData.row._id)},"rename":function($event){return _vm.showRenameModal(rowData.row._id, rowData.row.name, rowData.index)},"preview":function($event){return _vm.previewCampaign(rowData.row)}}},[_c('template',{slot:"duplicate"},[_c('span',[_vm._v(_vm._s(_vm.$t('duplicate')))])]),_c('template',{slot:"edit"},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('template',{slot:"rename"},[_c('span',[_vm._v(_vm._s(_vm.$t('rename')))])]),_c('template',{slot:"delete"},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])]),(!_vm.isEmbedded)?_c('template',{slot:"preview"},[_c('span',[_vm._v(_vm._s(_vm.$t('preview')))])]):_vm._e()],2)]:_vm._e()]}}])},[_c('template',{slot:"loadingItems"},[_c('skeleton-loader',{attrs:{"type":"variant","rows":_vm.skeletonRowNumber}})],1),_c('template',{slot:"loadingEmphasizedItems"},[_c('skeleton-loader',{attrs:{"type":"variant","rows":1}})],1),_c('template',{slot:"smartABTest"},[_vm._t("smartABTest")],2),_c('template',{slot:"addVariant"},[_vm._t("addVariant")],2)],2),_c('variant-rename-modal',{on:{"renameSuccess":function($event){return _vm.renameVariant($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }