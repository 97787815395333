<template lang="pug">
div(
  :lang="$i18n.locale"
  :class="{ 'multi-row-variant-table': emphasizedItems.length || items.length > 1 }"
)
  template(v-if="filterable")
    .brand-table-filter.d-flex.align-items-center
      slot(name="table-filter-row")
  .table-responsive.brand-campaigns-variants-table(v-if="emphasizedItems.length")
    .table.brand-table
      .thead(v-if="showHeader" @scroll="equalHorizontalScroll" ref="thead")
        .tr
          .th.col-select(v-if="selectable" scope="col")
            input.form-control(type="checkbox" v-model="allSelect")

          .th(
            v-if="isActiveColumn(column.key)"
            :class="getEmphasizedColumnClasses(column.key)"
            scope="col"
            v-for="column in emphasizedColumns"
          )
            slot(name="table-column" :column="column")
              span.cursor-pointer(
                v-if="sortable && (column.sortable === undefined || column.sortable !== false)"
                @click="sortBy(column)"
              )
                span {{ column.header }}
                template(v-if="column.header !== ''")
                  i.fas.ml-1(:class="sortIconClass(column)")
              span(v-else) {{ column.header }}
      .tbody(
        @scroll="equalHorizontalScroll"
        ref="tbody"
        :style="{ 'overflow-x': !items.length ? 'hidden' : null }"
      )
        slot(name="loadingEmphasizedItems" v-if="loadingContent")
        template(v-else)
          .tr.brand-table-tr(
            :key="row._id"
            v-for="(row, index) in emphasizedItems"
            :class="emphasizedHighlight(index)"
            @mouseleave="$emit('tableRowLeave', row)"
          )
            .td.brand-table-td.col-select(v-if="selectable")
              input.form-control(
                type="checkbox"
                :checked="isChecked(row._id)"
                @change="handleSingleSelect(row._id)"
              )
            template(v-if="isActiveColumn(column.key)" v-for="column in emphasizedColumns")
              .td(
                :class="getEmphasizedColumnClasses(column.key)"
                :title="needColumnTitle && column.key !== 'date' ? getRowValue(row, column.key) : null"
              )
                slot(:name="column.key" :rowData="{ column, row, index }") {{ getRowValue(row, column.key) }}
          .brand-box.brand-box-gray.text-center.flex-wrap.justify-content-center(
            v-if="items.length === 0 && !$store.state.showAdminLoader"
          )
            template(v-if="isSearchResult")
              .pt-3(v-if="!isSubUser")
                img(
                  src="@/assets/admin/img/monk-wave.png"
                  style="width: 13.25rem; height: 11.875rem"
                )
              .w-100.pt-4.font-size-1--5.font-weight-bold
                span {{ $t('noResults') }}
            template(v-if="!isSearchResult && !hadItems")
              div
                img(
                  v-if="$route.name === 'dashboard'"
                  src="@/assets/admin/svg/campaign-placeholder.svg"
                )
                img.pt-3(
                  v-else
                  src="@/assets/admin/img/monk.png"
                  style="width: 13.25rem; height: 11.875rem"
                )
  .table-responsive.brand-campaigns-variants-table
    .table.brand-table
      .thead(v-if="showHeader" @scroll="equalHorizontalScroll" ref="thead")
        .tr
          .th.col-select(v-if="selectable" scope="col")
            input.form-control(type="checkbox" v-model="allSelect")

          .th(
            v-if="isActiveColumn(column.key)"
            :class="getColumnClasses(column.key)"
            scope="col"
            v-for="column in columns"
          )
            slot(name="table-column" :column="column")
              span.cursor-pointer(
                v-if="sortable && (column.sortable === undefined || column.sortable !== false)"
                @click="sortBy(column)"
              )
                span {{ column.header }}
                template(v-if="column.header !== ''")
                  i.fas.ml-1(:class="sortIconClass(column)")
              span(v-else) {{ column.header }}
      .tbody(
        @scroll="equalHorizontalScroll"
        ref="tbody"
        :style="{ 'overflow-x': !items.length ? 'hidden' : null }"
      )
        slot(name="loadingItems" v-if="loadingContent")
        template(v-else)
          .tr.brand-table-tr(
            v-for="(row, index) in items"
            :key="row._id"
            :class="highlight(index)"
            @mouseleave="$emit('tableRowLeave', row)"
          )
            .td.brand-table-td.col-select(v-if="selectable")
              input.form-control(
                type="checkbox"
                :checked="isChecked(row._id)"
                @change="handleSingleSelect(row._id)"
              )
            template(v-if="isActiveColumn(column.key)" v-for="column in columns")
              .td(
                :class="getColumnClasses(column.key)"
                :title="needColumnTitle && column.key !== 'date' ? getRowValue(row, column.key) : null"
              )
                slot(:name="column.key" :rowData="{ column, row, index }") {{ getRowValue(row, column.key) }}
          .brand-box.brand-box-gray.text-center.flex-wrap.justify-content-center(
            v-if="items.length === 0 && !$store.state.showAdminLoader"
          )
            template(v-if="isSearchResult")
              .pt-3(v-if="!isSubUser")
                img(
                  src="@/assets/admin/img/monk-wave.png"
                  style="width: 13.25rem; height: 11.875rem"
                )
              .w-100.pt-4.font-size-1--5.font-weight-bold
                span {{ $t('noResults') }}
            template(v-if="!isSearchResult && !hadItems")
              div
                img(
                  v-if="$route.name === 'dashboard'"
                  src="@/assets/admin/svg/campaign-placeholder.svg"
                )
                img.pt-3(
                  v-else
                  src="@/assets/admin/img/monk.png"
                  style="width: 13.25rem; height: 11.875rem"
                )
  slot(name="smartABTest")
  slot(name="addVariant")
  nav.d-flex-flex-wrap(v-if="!customPaginationControls && paginate && allItemsCount")
    .py-4(v-if="allItemsCount > limits[0]")
      .row
        .col-sm-6.d-flex.justify-content-start.align-items-center.pb-4.px-4.bb-1
          om-pagination-list(
            :label="$t('viewPerPage')"
            v-model.number="pagination.limit"
            :pageFrom="itemsFrom"
            :pageTo="itemsTo"
            :allPageNum="allItemsCount"
            :limits="limits"
            @paginationLimitChange="limitChange($event)"
          )
        .col-sm-6.d-flex.justify-content-end.py-3(v-if="pageCount !== 1")
          om-pagination(
            :value="pagination.page"
            :maxPage="pageCount"
            @paginateTo="pageChange($event)"
          )
    .px-4
      .col-sm-12.p-0.d-flex.justify-content-between.align-items-center.font-size-0--875
        slot(name="table-footer")
</template>

<script>
  import { get as _get } from 'lodash-es';
  import { mapGetters } from 'vuex';

  export default {
    props: {
      needColumnTitle: { type: Boolean, default: false },
      emphasizedItems: { type: Array, default: () => [] },
      emphasizedColumns: { type: Array, required: true },
      emphasizedColumnClasses: { type: Object },
      highlightedEmphasizedRows: { type: Array, default: () => {} },
      highlightedEmphasizedClass: { type: String, default: 'highlighted' },
      items: { type: Array, required: true },
      loadingContent: { type: Boolean, default: false },
      columns: { type: Array, required: true },
      columnClasses: { type: Object },
      availableColumns: { type: Array },
      highlightedRows: { type: Array, default: () => [] },
      highlightClass: { type: String, default: 'highlighted' },
      selectable: { type: Boolean, default: true },
      sortable: { type: Boolean, default: true },
      paginate: { type: Boolean, default: true },
      filterable: { type: Boolean, default: false },
      allItemsCount: { type: Number },
      showHeader: { type: Boolean, default: true },
      pagination: {
        type: Object,
        default: () => {
          return { page: 1, limit: 5 };
        },
      },
      sorting: { type: Object },
      isSearchResult: { type: Boolean, default: false },
      limits: {
        type: Array,
        default: () => {
          return [5, 10, 30];
        },
      },
      customPaginationControls: { type: Boolean, default: false },
    },

    data() {
      return {
        selectedIds: [],
        allSelect: false,
        hadItems: false, // dont show greetings texts if table had items
        innerSorting: this.sorting
          ? this.sorting
          : this.columns.find((c) => c.key === 'createdAt')
          ? { field: 'createdAt', direction: -1 }
          : { field: 'status', direction: 1 },
      };
    },

    computed: {
      ...mapGetters(['isSubUser']),
      itemsFrom() {
        return (this.pagination.page - 1) * this.pagination.limit + 1;
      },
      itemsTo() {
        const pageCapacity = this.pagination.page * this.pagination.limit;
        return pageCapacity > this.allItemsCount ? this.allItemsCount : pageCapacity;
      },
      pageCount() {
        return Math.ceil(this.allItemsCount / this.pagination.limit);
      },
      highlight() {
        return (index) => {
          return this.highlightedRows.indexOf(`${index}`) !== -1 ? this.highlightClass : '';
        };
      },
      emphasizedHighlight() {
        return (index) => {
          return this.highlightedEmphasizedRows.indexOf(index) !== -1
            ? this.highlightedEmphasizedClass
            : '';
        };
      },
    },

    watch: {
      allItemsCount(v) {
        if (v > 0) this.hadItems = true;
      },
      allSelect(v) {
        this.selectedIds = v ? this.items.map((i) => i._id) : [];
        this.$bus.$emit('allSelectTable', v);
      },
      selectedIds() {
        this.$emit('selectedIdsChange', this.selectedIds);
      },
      innerSorting: {
        handler() {
          this.$emit('sortingChange', this.innerSorting);
        },
        deep: true,
      },
    },

    mounted() {
      this.$bus.$on('allSelectTableBulk', (v) => {
        this.allSelect = v;
      });
      this.$bus.$on('deselectAllTable', () => {
        this.selectedIds = [];
        this.allSelect = false;
      });
      this.$bus.$on('resetTableHadItems', this.resetHadItems);
    },

    beforeDestroy() {
      this.$bus.$off('resetTableHadItems', this.resetHadItems);
    },
    methods: {
      resetHadItems() {
        this.hadItems = false;
      },
      equalHorizontalScroll(e) {
        if (e.target.classList.contains('thead')) {
          this.$refs.tbody.scrollLeft = e.target.scrollLeft;
        } else {
          this.$refs.thead.scrollLeft = e.target.scrollLeft;
        }
      },
      limitChange(newLimit) {
        const page = 1;
        this.$emit('paginationChange', { page, limit: parseInt(newLimit, 10) });
      },
      pageChange(newPage) {
        this.$emit('paginationChange', {
          page: parseInt(newPage, 10),
          limit: parseInt(this.pagination.limit, 10),
        });
      },
      isActiveColumn(value) {
        if (!this.availableColumns) return true;
        return !!this.availableColumns.includes(value);
      },
      isChecked(_id) {
        return this.selectedIds.indexOf(_id) > -1;
      },
      handleSingleSelect(_id) {
        const index = this.selectedIds.indexOf(_id);
        if (index === -1) {
          // add
          this.selectedIds.push(_id);
        } else {
          // remove
          this.selectedIds.splice(index, 1);
        }
      },
      sortBy(column) {
        // this.pagination.page = this.pagination.page !== 1 ? 1 : this.pagination.page // reset if not on first page

        if (column.sortKey) {
          if (this.innerSorting.field === column.sortKey) {
            this.innerSorting.direction = this.innerSorting.direction === 1 ? -1 : 1;
          } else {
            this.innerSorting.field = column.sortKey;
            this.innerSorting.direction = -1;
          }
          return;
        }

        if (this.innerSorting.field === column.key) {
          this.innerSorting.direction = this.innerSorting.direction === 1 ? -1 : 1;
        } else {
          this.innerSorting.field = column.key;
          this.innerSorting.direction = -1;
        }
      },
      genPagination() {
        const delta = 2;
        const left = this.pagination.page - delta;
        const right = this.pagination.page + delta + 1;
        let pages = [];

        pages = Array.from({ length: this.pageCount }, (v, k) => k + 1).filter(
          (i) => i && i >= left && i < right,
        );

        return pages;
      },
      getColumnClasses(key) {
        return this.columnClasses !== undefined && this.columnClasses[key] !== undefined
          ? this.columnClasses[key]
          : [];
      },
      getEmphasizedColumnClasses(key) {
        return this.emphasizedColumnClasses !== undefined &&
          this.emphasizedColumnClasses[key] !== undefined
          ? this.emphasizedColumnClasses[key]
          : [];
      },
      getRowValue(row, path) {
        return _get(row, path);
      },
      sortIconClass(column) {
        if (column.sortKey) {
          if (this.innerSorting.field === column.sortKey) {
            return {
              'fa-sort-up': this.innerSorting.direction === 1,
              'fa-sort-down': this.innerSorting.direction === -1,
            };
          }
          return {
            'fa-sort': true,
          };
        }

        if (this.innerSorting.field === column.key) {
          return {
            'fa-sort-up': this.innerSorting.direction === 1,
            'fa-sort-down': this.innerSorting.direction === -1,
          };
        }
        return {
          'fa-sort': true,
        };
      },
    },
  };
</script>
