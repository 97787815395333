import DISMISS_CAMPAIGN_ALERTS from '@/graphql/DismissCampaignAlerts.gql';
import { arrayify } from '@om/common/src/utils';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isShopifyDomain']),
  },
  methods: {
    async dismissAlert(alert) {
      const campaignId = alert.links.find((link) => link.type === 'Campaign').id;
      await this.$apollo.query({
        query: DISMISS_CAMPAIGN_ALERTS,
        variables: {
          campaignId,
          types: [alert.type],
        },
      });
      this.$emit('dismiss', alert);
    },
    getLinkId(links, linkType) {
      const link = links.find(({ type }) => type === linkType);
      return link ? link.id : {};
    },
    getVariantNameById(variantId) {
      const variants = this.variants?.variants || this.variantsData?.variants || this.variants;
      const variant = variants.find(({ _id }) => _id === variantId);

      return variant?.name || '-';
    },
    getVariantName(alert) {
      const variants = this.variants?.variants || this.variantsData?.variants;
      if (!variants || !alert.links) return '-';
      const variantId = this.getLinkId(alert.links, 'Variant');
      const variant = variants.find(({ _id }) => _id === variantId);
      return variant?.name || '-';
    },
    descriptionOptions(alert) {
      return {
        ...this.getPrefixedContext(alert),
        variantName: this.getVariantName(alert),
      };
    },
    getPrefixedContext({ context }) {
      if (!context) return {};
      const prefixedContext = {};
      Object.entries(context).forEach(([key, value]) => {
        if (key === 'type') {
          prefixedContext[`ctx_${key}`] = Array.isArray(value) ? value.join(', ') : value;
        } else if (key === 'variants') {
          const variantNames = arrayify(value).map((v) => this.getVariantNameById(v));
          prefixedContext[`ctx_${key}`] = variantNames;
        }
      });
      return prefixedContext;
    },
    hasListItems(type) {
      return [
        'ProductOutOfStock',
        'CampaignAlmostOutOfDiscountCodes',
        'CampaignOutOfDiscountCodes',
      ].includes(type);
    },
    getListItems(alert) {
      if (alert.type === 'ProductOutOfStock') return this.getProductItems(alert);
      if (['CampaignAlmostOutOfDiscountCodes', 'CampaignOutOfDiscountCodes'].includes(alert.type))
        return this.getVariantItems(alert);
      return [];
    },
    getVariantItems(alert) {
      return alert?.context?.variants.map((id) => {
        const variantName = this.getVariantNameById(id);
        return {
          variantName,
        };
      });
    },
    getProductItems(alert) {
      const variantName = this.getVariantName(alert);
      return alert?.context?.products.map(({ title }) => {
        return {
          variantName,
          productName: title,
        };
      });
    },
    getAlertTypeWithV3(type) {
      if (['InsertCodeV3MissingWarning', 'InsertCodeV3MissingError'].includes(type)) {
        const domainType = this.isShopifyDomain(this.campaign?.domain || this.domain)
          ? 'shopify'
          : 'nonShopify';
        return `InsertCodeV3Missing_${domainType}`;
      }
      return type;
    },
  },
};
