<template lang="pug">
.card.experiences
  .d-flex.justify-content-sm-between.align-center.mb-5.flex-wrap
    .card-title.pr-3 {{ $t('experiences.module.title') }}
    span.how-it-works
      om-button(ghost color="#505763" iconSize="24px" icon="question-circle" @click="howItWorks") {{ $t('experiences.module.howItWorks') }}
    .d-flex.experiences-actions-wrapper
      om-select#goal.mr-2.goal-select(
        v-if="!shouldShowDefineGoalButton"
        :value="activeExperienceGoal"
        :options="allGoals"
        optionKey="_id"
        optionText="name"
        :label="$t('conversionGoals.selectLabel')"
        labelPosition="fill"
        extendable
        :addNewText="$t('conversionGoals.addNewText')"
        @addNew="openCreateGoalModal(campaign.domainId)"
        @input="changeSelectedGoal"
      )
      om-tooltip.mr-2.d-flex.align-items-center(placement="top")
        span {{ $t('conversionGoals.tooltip') }}
      om-button.mr-3(
        secondary
        small
        v-if="shouldShowDefineGoalButton"
        @click="openCreateGoalModal(campaign.domainId)"
      ) {{ $t('conversionGoals.defineConversionGoal') }}
      om-button.experience-add-new(primary @click="addNewExperience") {{ $t('experiences.module.addNew') }}
  .row.experience-list
    template(v-for="(experience, index) in campaign.experiences")
      Experience(
        ref="experiences"
        :index="index"
        :campaign="campaign"
        :experience="experience"
        :allVariants="allVariants"
        :loadingGoals="loadingGoals"
        :isDefaultGoal="isDefaultGoal"
        @variantStatusUpdate="$emit('variantStatusUpdate', $event)"
        @changeVariantName="$emit('changeVariantName', $event)"
        @refetchExperiences="refetchExperiences"
        @addNewVariant="handleAddNewVariant($event)"
        @deleteExperience="deleteExperience($event, index)"
        @deleteRule="modifyRules('delete', $event)"
        @changePriority="changePriority($event, index)"
        @openRuleModal="openRuleModal(experience, index)"
        @updateStopTestRunning="$emit('updateStopTestRunning', $event)"
      )
  delete-experience-modal(@markAsDeleted="markAsDeleted($event)")
  rename-experience-modal(@changeExperienceName="$emit('changeExperienceName', $event)")
  new-campaign-setting-rule(
    settingType="frontendRules"
    :settingArray.sync="campaign.frontendRules"
    @editRule="modifyRules('add', $event)"
    :campaign="campaign"
    :domain="campaign.domain"
  )
  portal(to="root")
    product-catalog
</template>
<script>
  import CREATE_EXPERIENCE from '@/graphql/CreateExperience.gql';
  import UPSERT_RULES_IN_EXPERIENCE from '@/graphql/UpsertRulesInExperience.gql';
  import Experience from '@/components/Experiences/Experience.vue';
  import DeleteExperienceModal from '@/components/Modals/DeleteExperience.vue';
  import RenameExperienceModal from '@/components/Modals/RenameExperience.vue';
  import isDynamicContent from '@/mixins/dynamicContent';
  import DELETE_EXPERIENCE from '@/graphql/DeleteExperience.gql';
  import NewCampaignSettingRule from '@/components/Modals/NewCampaignSettingRule.vue';
  import CHANGE_EXPERIENCE_PRIORITY from '@/graphql/ChangeExperiencePriority.gql';
  import cookieMixin from '@/mixins/cookie';
  import ADD_VARIANT_TO_EXPERIENCE from '@/graphql/AddVariantToExperience.gql';
  import embeddedV3Mixin from '@/mixins/embeddedV3';
  import { track } from '@/services/xray';
  import NEW_VARIANT from '@/graphql/NewVariant.gql';
  import { mapMutations, mapGetters } from 'vuex';
  import ProductCatalog from '@/editor/components/modals/ProductCatalog.vue';
  import { RULE_CART_RULES_V2 } from '@/config/frontendRules/rules';
  import productFetchMixin from '@/mixins/productFetch';
  import goalsMixin from '@/mixins/goals';

  export default {
    name: 'ExperienceTable',
    components: {
      Experience,
      DeleteExperienceModal,
      NewCampaignSettingRule,
      RenameExperienceModal,
      ProductCatalog,
    },
    mixins: [isDynamicContent, cookieMixin, embeddedV3Mixin, productFetchMixin, goalsMixin],
    props: {
      campaign: {
        type: Object,
        default: () => {},
      },
      allVariants: {
        type: Array,
        default: () => [],
      },
      goals: {
        type: Array,
        default: () => [],
      },
      goal: {
        type: Object,
        default: () => {},
      },
      loadingGoals: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      currentCartRules: {
        rules: {},
        editedGroup: null,
        editedExpression: null,
      },
      lastEditedExperience: null,
      activeExperienceGoal: null,
    }),
    computed: {
      ...mapGetters(['isActiveShopifyDomain', 'isActiveShoprenterDomain']),
      campaignType() {
        if (this.isEmbeddedV3) {
          return 'embeddedv3';
        }

        if (this.isDynamicContent) {
          return 'dynamic_content';
        }

        return this.isEmbedded ? 'embedded' : 'default';
      },
    },
    watch: {
      'campaign.experiences': {
        async handler() {
          await this.checkCartRule();
        },
        deep: true,
      },
      goal() {
        if (this.goal) {
          this.activeExperienceGoal = this.goal;
        }
      },
    },
    async mounted() {
      if (this.goal) {
        this.activeExperienceGoal = this.goal;
      }
      await this.checkCartRule();
      this.$bus.$on('openCatalog', this.openCatalog);
      this.$bus.$on('cancelCatalog', this.reOpenVisitorCartRules);
      this.$bus.$on('saveProductSelection', this.saveProductSelection);
    },
    beforeDestroy() {
      this.$bus.$off('openCatalog', this.openCatalog);
      this.$bus.$off('cancelCatalog', this.reOpenVisitorCartRules);
      this.$bus.$off('saveProductSelection', this.saveProductSelection);
    },
    methods: {
      ...mapMutations(['showAdminLoader']),
      getSuccessText(type) {
        return type === 'add'
          ? this.$t('notifications.saveSuccess')
          : this.$t('notifications.deleteSuccess');
      },
      getErrorText(type) {
        return type === 'add'
          ? this.$t('notifications.saveError')
          : this.$t('notifications.deleteError');
      },
      buildCorrectExperiencesArray(direction, changedElementIndex) {
        const experiences = [...this.campaign.experiences];
        const changedElement = experiences[changedElementIndex];
        const siblingElementIndex = changedElementIndex - direction;

        experiences[changedElementIndex] = experiences.splice(
          siblingElementIndex,
          1,
          changedElement,
        )[0];

        experiences.forEach((_, index) => {
          const priority = experiences.length - index - 1; // -1 because indexing starts from 0 next priority determined by count
          experiences[index].priority = priority;
        });

        return experiences;
      },
      async changePriority(direction, changedElementIndex) {
        const experiences = this.buildCorrectExperiencesArray(direction, changedElementIndex);

        this.$emit('updateExperiencesOrder', experiences);
        this.savePriorities(experiences);
      },
      async savePriorities(experiences) {
        const priorities = experiences.map(({ _id: experienceId, priority }) => ({
          experienceId,
          priority,
        }));

        const { data } = await this.$apollo.mutate({
          mutation: CHANGE_EXPERIENCE_PRIORITY,
          variables: { priorities },
        });

        if (!data?.changeExperiencePriority?.success) {
          this.$notify({
            type: 'error',
            text: 'Failed to change experience priority',
          });
        }
      },
      overrideOrAdd(editedRules, overriddenRule) {
        const overridingIndex = editedRules.findIndex(({ type }) => type === overriddenRule.type);

        if (overridingIndex >= 0) {
          editedRules.splice(overridingIndex, 1, overriddenRule);
        } else {
          editedRules.push(overriddenRule);
        }
      },
      async modifyRules(
        type,
        { experienceId, frontendRule: overriddenRule, frontendRuleIndex } = {},
      ) {
        const editedExperience = this.campaign.experiences.find(({ _id }) => _id === experienceId);
        if (!editedExperience) {
          console.error('Missing experience!');
          return;
        }

        const editedRules = [...(editedExperience?.frontendRules || [])];

        if (type === 'delete') {
          editedRules.splice(frontendRuleIndex, 1);
        } else {
          this.overrideOrAdd(editedRules, overriddenRule);
        }

        await this.upsertRules(experienceId, { frontendRules: editedRules, type });
      },
      async upsertRules(experienceId, { frontendRules, type }) {
        const { data } = await this.$apollo.mutate({
          mutation: UPSERT_RULES_IN_EXPERIENCE,
          variables: { experienceId, frontendRules },
        });

        const isSuccess = data?.upsertRulesInExperience?.success;

        this.$emit('refetchExperiences');
        this.$notify({
          type: isSuccess ? 'success' : 'error',
          text: isSuccess ? this.getSuccessText(type) : this.getErrorText(type),
        });
      },
      howItWorks() {
        const supportPage = 'https://support.optimonk.com/hc/en-us/articles/8805751837202';
        window.open(supportPage, '_blank');
      },

      async copyFirstVariant(experienceId) {
        const variantId = this.allVariants[0]._id;
        await this.$apollo.query({
          query: ADD_VARIANT_TO_EXPERIENCE,
          variables: {
            variantId,
            experienceId,
          },
        });
        track('add-variant-to-experience', {
          experienceId,
          variantId,
        });
        this.refetchExperiences();
      },

      async addDynamicContentToExperience({ experienceId }) {
        this.showAdminLoader(true);
        await this.$apollo.mutate({
          mutation: NEW_VARIANT,
          variables: { input: { _id: this.campaign?._id } },
        });
        this.$emit('addDynamicContentToExperience', { experienceId });
      },

      async handleAddNewVariant({ experienceId }) {
        if (this.allVariants.length === 1) {
          return this.copyFirstVariant(experienceId);
        }

        this.$modal.show('add-variant-to-experience', {
          campaign: this.campaign,
          variants: this.allVariants,
          experienceId,
        });
      },
      async addNewExperience() {
        await this.$apollo.mutate({
          mutation: CREATE_EXPERIENCE,
          variables: {
            campaign: this.campaign._id,
          },
        });
        this.$emit('addNewExperience', { openFirst: true });
      },
      refetchExperiences(event) {
        this.$emit('refetchExperiences', event);
      },
      openAccordion(index) {
        this.$refs?.experiences?.[index]?.open();
      },
      hasVariantInOtherExperiences(targetIndex) {
        return this.campaign.experiences.some(
          (experience, experienceIndex) =>
            experienceIndex !== targetIndex && experience.variants.length,
        );
      },
      canDelete(targetIndex) {
        const target = this.campaign.experiences[targetIndex];

        if (!target) return false;

        return !target.variants.length || this.hasVariantInOtherExperiences(targetIndex);
      },
      async deleteExperience(experienceId, index) {
        if (!this.canDelete(index)) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.deleteError'),
          });
          return;
        }

        const showDeleteModal = Boolean(this.getCookie('x-om-not-show-delete-experience-modal'));
        if (showDeleteModal) {
          await this.markAsDeleted(experienceId);
        } else {
          const { name } = this.campaign.experiences[index];
          this.$modal.show('delete-experience-modal', { experienceId, experienceName: name });
        }
      },
      async markAsDeleted(experienceId) {
        const { data } = await this.$apollo.mutate({
          mutation: DELETE_EXPERIENCE,
          variables: {
            experienceId,
          },
        });

        if (data?.deleteExperience?.success) {
          this.$emit('refetchExperiences', true);
          this.$notify({
            type: 'success',
            text: this.$t('notifications.deleteSuccess'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.deleteError'),
          });
        }
      },
      hasVisitorCartRevampRule() {
        let hasRevampRule = false;
        this.campaign.experiences.forEach(({ frontendRules }) => {
          const experienceHasRevampRule = frontendRules.some(
            (rule) => rule.type === RULE_CART_RULES_V2,
          );
          if (experienceHasRevampRule) hasRevampRule = true;
        });
        return hasRevampRule;
      },
      openRuleModal(experience) {
        this.lastEditedExperience = experience;
        this.$modal.show('new-frontendrules-modal', {
          ruleset: this.campaignType,
          experience,
        });
      },

      reOpenVisitorCartRules() {
        this.$modal.show('new-frontendrules-modal', {
          ruleset: this.campaignType,
          currentPage: 2,
          ruleType: RULE_CART_RULES_V2,
          type: this.currentCartRules.isEditMode ? RULE_CART_RULES_V2 : '',
          currentCartRules: this.currentCartRules.rules,
          experience: this.lastEditedExperience,
        });
      },
      saveProductSelection(selectedProducts) {
        const currentEditedExpression =
          this.currentCartRules.rules.options.expressions?.[this.currentCartRules.editedGroup]?.[
            this.currentCartRules.editedExpression
          ] ?? this.currentCartRules.rules.options.expressions?.[this.currentCartRules.editedGroup];

        if (this.currentCartRules.ruleType) {
          if (!currentEditedExpression.meta) currentEditedExpression.meta = {};
          currentEditedExpression.meta.products = selectedProducts;

          currentEditedExpression.value = selectedProducts.map((product) => product.id || product);
        } else {
          currentEditedExpression.value = selectedProducts;
        }

        this.$modal.show('new-frontendrules-modal', {
          ruleset: this.campaignType,
          currentPage: 2,
          ruleType: RULE_CART_RULES_V2,
          type: this.currentCartRules.isEditMode ? this.currentCartRules.ruleType : '',
          currentCartRules: this.currentCartRules.rules,
          experience: this.lastEditedExperience,
        });
      },
      openCatalog({ ruleType, currentRules, groupIndex, exprIndex, isEditMode }) {
        this.currentCartRules.rules = currentRules;
        this.currentCartRules.editedGroup = groupIndex;
        this.currentCartRules.editedExpression = exprIndex;
        this.currentCartRules.isEditMode = isEditMode;
        this.currentCartRules.ruleType = ruleType ?? null;

        const selectedProducts =
          currentRules.options.expressions?.[groupIndex]?.[exprIndex]?.value ??
          currentRules.options.expressions?.[groupIndex]?.meta?.products ??
          [];

        const setProducts = Array.isArray(selectedProducts)
          ? selectedProducts.filter(
              // Filter out "null" items
              (prod) => !!prod,
            )
          : [];

        this.$modal.hide('new-frontendrules-modal');
        this.$modal.show('product-catalog', {
          type: this.isActiveShopifyDomain(this.campaign.domain) ? 'shopify' : 'shoprenter',
          campaign: this.campaign,
          setProducts,
        });
      },
      collectProductIdsFromVisitorCartRevampRules() {
        const ids = [];
        this.campaign.experiences.forEach(({ frontendRules }) => {
          frontendRules.forEach((rule) => {
            if (rule.type === 'visitorCartRevamp') {
              rule.options.expressions.forEach((orGroup) => {
                orGroup.forEach((andItem) => {
                  if (Array.isArray(andItem.value)) {
                    ids.push(...andItem.value);
                  }
                });
              });
            }
          });
        });
        return ids;
      },
      async checkCartRule() {
        if (this.hasVisitorCartRevampRule()) {
          const productIds = this.collectProductIdsFromVisitorCartRevampRules();
          if (this.isActiveShopifyDomain(this.campaign.domain)) {
            const productDetails = await this.getShopifyProductDetails(productIds);
            if (productDetails && productDetails.length) {
              this.campaign.experiences.forEach((_, index) => {
                this.mergeProductDetailsWithRules(
                  this.campaign.experiences[index].frontendRules,
                  productDetails,
                  'shopify',
                );
              });
            }
          }
          if (this.isActiveShoprenterDomain(this.campaign.domain)) {
            const productDetails = await this.getShoprenterProductDetails(productIds);
            if (productDetails && productDetails.length) {
              this.campaign.experiences.forEach((_, index) => {
                this.mergeProductDetailsWithRules(
                  this.campaign.experiences[index].frontendRules,
                  productDetails,
                  'shoprenter',
                );
              });
            }
          }
        }
      },
      changeSelectedGoal(event) {
        this.$emit('changeSelectedGoal', event);
      },
    },
  };
</script>
<style lang="sass">
  .card-title
    font-size: 1.5rem
    line-height: 1.33
    margin-bottom: 0
  .experience-item
    &:first-child
      .priority-increase
        opacity: 0.5
        pointer-events: none
    &:last-child
      .priority-decrease
          opacity: 0.5
          pointer-events: none
  @media (max-width: 1250px)
    .experiences-actions-wrapper
      width: 100%
      margin-top: 1rem
      .goal-select
        flex: 0 0 50%!important
      .experience-add-new
        margin-left: auto
</style>
