<template lang="pug">
div
  slot
    i18n.alert-how-to-fix(
      v-if="getAlertTypeWithV3(alert.type) === 'InsertCodeV3Missing_shopify'"
      path="campaignAlerts.InsertCodeV3Missing_shopify.howToFix"
      tag="small"
    )
      a(href="javascript:void(0);" @click="toggleShopifyAppExtension(shop.myshopify_domain, true)") {{ $t('campaignAlerts.InsertCodeV3Missing_shopify.howToFix_link') }}

    small.alert-how-to-fix(
      v-else
      v-html="$t(`campaignAlerts.${getAlertTypeWithV3(alert.type)}.howToFix`, options)"
    )
</template>
<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import userAlert from '@/mixins/userAlert';
  import shopifyAppExtension from '@/mixins/shopifyAppExtension';

  export default {
    mixins: [userAlert, shopifyAppExtension],
    props: {
      alert: {
        type: Object,
        required: true,
      },
      campaignId: {
        type: Number,
        required: true,
      },
      userId: {
        type: Number,
        required: true,
      },
      campaign: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters(['account', 'shopSettingsByDomain']),
      variant() {
        return this.alert?.links?.find(({ type }) => type === 'Variant');
      },
      editorLink() {
        if (!this.variant) return '#';

        return `/${this.userId}/variant/${this.campaignId}/${this.variant.id}/edit`;
      },
      insertCodeLink() {
        const resolvedRoute = this.$router.resolve({
          name: 'settings-code-insert-domain',
          params: { userId: this.userId },
          query: { campaign: this.campaignId, domain: this.campaign.domain },
        });
        return resolvedRoute.route.fullPath;
      },
      shop() {
        if (!this.campaign) return null;
        return this.shopSettingsByDomain(this.campaign.domain);
      },
      storeAdminLink() {
        if (!this.shop) return '#';
        return `https://${this.shop.live_domain}/admin`;
      },
      filteredLeadsLink() {
        const resolvedRoute = this.$router.resolve({
          name: 'subscribers',
          params: { userId: this.userId, campaignId: this.campaignId },
          query: {
            syncStatus: 'failed',
            dateStart: moment().subtract(1, 'week').startOf('day').unix(),
          },
        });
        return resolvedRoute.route.fullPath;
      },
      options() {
        return {
          editorLink: this.editorLink,
          insertCodeLink: this.insertCodeLink,
          storeAdminLink: this.storeAdminLink,
          recartLoginLink: 'https://app.recart.com/login',
          manyChatLoginLink: 'https://manychat.com/login',
          shopMessageLoginLink: 'https://login.shopmessage.me/login',
          filteredLeadsLink: this.filteredLeadsLink,
        };
      },
    },
  };
</script>
