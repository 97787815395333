const campaignAlertByTypes = {
  error: [
    'InsertCodeMissing',
    'InsertCodeV3MissingError',
    'CampaignOutOfDiscountCodes',
    'ProductOutOfStock',
  ],
  warning: [
    'NoRequestFromDomain',
    'IntegrationError',
    'CampaignAlmostOutOfDiscountCodes',
    'InsertCodeV3MissingWarning',
  ],
};

const computedCampaignAlertTypes = [
  'NoRequestFromDomain',
  'InsertCodeMissing',
  'InsertCodeV3MissingError',
  'InsertCodeV3MissingWarning',
];

export { campaignAlertByTypes, computedCampaignAlertTypes };
