var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('om-table',{attrs:{"items":_vm.subscribers,"columns":_vm.columns,"columnClasses":_vm.columnClasses,"allItemsCount":_vm.subscriberCount,"isSearchResult":_vm.isSearchResult,"selectable":_vm.selectable,"paginate":_vm.paginate,"pagination":_vm.pagination,"needColumnTitle":true,"loadingContent":_vm.loadingSubscribers},on:{"selectedIdsChange":function($event){return _vm.$emit('selectedIdsChange', $event)},"sortingChange":function($event){return _vm.$emit('sortingChange', $event)},"paginationChange":function($event){return _vm.$emit('paginationChange', $event)}},scopedSlots:_vm._u([_vm._l((_vm.columns),function(col){return {key:col.key,fn:function(ref){
var rowData = ref.rowData;
return (col.key.includes('customFields'))?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(col.key.includes('customFields') ? {autoHide: false, content: _vm.getRowData(rowData.row, col.key)} : ''),expression:"col.key.includes('customFields') ? {autoHide: false, content: getRowData(rowData.row, col.key)} : ''"}],staticClass:"subscriber-table-value"},[_vm._v(_vm._s(_vm.getRowData(rowData.row, col.key)))])]:undefined}}}),{key:"contact",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('om-avatar',{staticClass:"mr-3",attrs:{"name":{ firstName: rowData.row.firstName, lastName: rowData.row.lastName },"email":rowData.row.email,"colorize":""}}),_c('div',{staticClass:"d-flex flex-column brand-avatar-info"},[_c('div',{staticClass:"brand-avatar-name"},[_vm._v(_vm._s(_vm.getFullNameByLocale(rowData.row.firstName, rowData.row.lastName, _vm.$i18n.locale)))]),_c('div',{staticClass:"brand-avatar-email"},[_vm._v(_vm._s(rowData.row.email))])])],1)]}},{key:"date",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatDateTime(rowData.row.date))}})]}},{key:"syncStatus",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.hasIntegrationError(rowData.row))?_c('div',{staticClass:"subscriber-table-integration-error",on:{"click":function($event){return _vm.showIntegrationErrorDetails(rowData.row)}}},[_c('integration-error-indicator',{attrs:{"small":""}}),_c('span',[_vm._v(_vm._s(_vm.$t('syncStatus.' + _vm.getIntegrationErrorText(rowData.row))))])],1):[_c('span',[_vm._v(_vm._s(_vm.$t('syncStatus.' + rowData.row.syncStatus)))])]]}}],null,true)},[_c('template',{slot:"loading"},[_c('skeleton-loader',{attrs:{"type":"subscriber","rows":_vm.skeletonRowNumber}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }