<template lang="pug">
om-modal.campaign-alerts-modal(
  name="campaign-alerts-modal"
  @beforeOpen="beforeOpen"
  width="810"
  color="light"
)
  template(slot="modal-header")
    .w-100.text-left.font-weight-bold {{ $t('campaignAlerts.modal.title') }} ({{ alertsCount }})
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('campaign-alerts-modal')")
        close-icon(:width="14" :height="14" :color="'#C2C2C2'")
  template(slot="modal-body")
    .campaign-alerts-modal-campaign-name.mt-3.mb-3 {{ campaign.name }}
    .table-responsive
      table.table.table-fixed
        thead
          tr
            th.col-7(colspan="2") {{ $t('campaignAlerts.modal.error') }} / {{ $t('campaignAlerts.modal.alert') }}
            th.col-3 {{ $t('campaignAlerts.modal.lastTriggered') }}
            th.col-2 {{ $t('campaignAlerts.modal.action') }}
        tbody
          template(v-for="(item, index) in alerts")
            tr
              td.col-7.alert-col.col-with-padding(
                @click="toggleHowTo(index)"
                :class="{ 'table-toggle-btn-open': activeSlide === index, 'cursor-pointer': multipleAlerts }"
              )
                uil-angle-right.table-toggle-btn(:size="'16px'" v-if="multipleAlerts")
                uil-exclamation-triangle(
                  v-if="getAlertType(item.type) === 'error'"
                  :size="'24px'"
                  :color="errorColor"
                )
                uil-exclamation-circle(v-else :size="'24px'" :color="warningColor")
                span.alert-name {{ $t(`campaignAlerts.${getAlertTypeWithV3(item.type)}.title`) }}
              td.col-3 {{ lastTriggeredDate(item.triggeredAt) }}
              td.col-2
                om-button(
                  secondary
                  small
                  @click="dismissAlert(item)"
                  v-if="!isComputedAlert(item)"
                ) {{ $t('campaignAlerts.modal.dismiss') }}
            tr.table-toggle-row(:class="{ active: activeSlide === index }")
              td.col-12.col-with-padding.pr-4(colspan="4")
                small.alert-description.mb-3(
                  v-html="$t(`campaignAlerts.${getAlertTypeWithV3(item.type)}.description`, descriptionOptions(item))"
                )
                ul(v-if="hasListItems(item.type)")
                  li(
                    v-for="row in getListItems(item)"
                    v-html="$t(`campaignAlerts.${item.type}.item`, row)"
                  )
                alert-how-to-fix(
                  :alert="item"
                  :campaign="campaign"
                  :campaign-id="campaignId"
                  :userId="userId"
                )
                  template(v-if="isListFixInstructions(item)")
                    slot
                      ul.how-to-install-list
                        li(
                          v-for="index in 3"
                          v-html="$t(`campaignAlerts.${getAlertTypeWithV3(item.type)}.step${index}`, { insertCodePage })"
                        )
  template(slot="modal-footer")
    hr.my-0(:style="'height: 2px'")
    .brand-modal-footer-content
      om-heading.mb-3(h3)
        i.fas.fa-info-circle.mr-2
        span {{ $t('campaignAlerts.modal.howItWorks') }}
      om-body-text(bt400md)
        span(v-html="$t('campaignAlerts.modal.howItWorksText')")
</template>
<script>
  import {
    UilExclamationTriangle,
    UilExclamationCircle,
    UilAngleRight,
  } from '@iconscout/vue-unicons';
  import moment from 'moment';
  import { campaignAlertByTypes, computedCampaignAlertTypes } from '@/utils/campaignAlerts';
  import AlertHowToFix from '@/components/Campaign/AlertHowToFix';
  import dateFormat from '@/mixins/dateFormat';
  import userAlert from '@/mixins/userAlert';
  import { getAccountIdFromCookie } from '@/util';

  export default {
    components: { UilExclamationTriangle, UilExclamationCircle, UilAngleRight, AlertHowToFix },
    mixins: [dateFormat, userAlert],
    props: {
      alerts: {
        type: [Array, Object],
        default: () => [],
      },
      campaign: {
        type: Object,
        required: true,
      },
      variants: {
        type: [Array, Object],
        default: () => [],
        required: true,
      },
    },
    data() {
      return {
        activeSlide: null,
        campaignAlertByTypes,
        errorColor: '#E4252D',
        warningColor: '#F0C920',
      };
    },
    computed: {
      alertsCount() {
        return this.alerts.length;
      },
      multipleAlerts() {
        return this.alertsCount > 1;
      },
      campaignId() {
        return this.$route.params.id;
      },
      userId() {
        return getAccountIdFromCookie();
      },
      insertCodePage() {
        const resolvedRoute = this.$router.resolve({
          name: 'settings-code-insert-domain',
          params: { userId: this.userId },
          query: { campaign: this.campaignId, domain: this.campaign.domain },
        });
        return resolvedRoute.route.fullPath;
      },
    },
    watch: {
      multipleAlerts() {
        if (!this.multipleAlerts) {
          this.activeSlide = 0;
        }
      },
    },
    methods: {
      beforeOpen({ params }) {
        this.activeSlide = params.activeSlide ?? null;
      },
      toggleHowTo(index) {
        if (this.multipleAlerts) {
          this.activeSlide = this.activeSlide === index ? null : index;
        }
      },
      isComputedAlert(alert) {
        return computedCampaignAlertTypes.includes(alert.type);
      },
      getAlertType(type) {
        return campaignAlertByTypes.error.includes(type) ? 'error' : 'warning';
      },
      lastTriggeredDate(date) {
        const today = moment();
        const dateToCheck = moment(date);
        const lessThanOneDay = today.diff(dateToCheck, 'd') < 1;
        return lessThanOneDay
          ? moment(date).format(this.longDateTimeFormat)
          : moment(date).fromNow();
      },
      isListFixInstructions(item) {
        return (
          item.type.startsWith('InsertCodeV3Missing') && !this.isShopifyDomain(this.campaign.domain)
        );
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .table thead tr
    position: relative
  .table-toggle-btn
    cursor: pointer
    position: absolute
    top: 0.85rem
    left: 0.4rem
    transition: 300ms
    .table-toggle-btn-open &
      transform: rotate(90deg)
  .table-toggle-row
    visibility: hidden
    opacity: 0
    transition: visibility 0s, opacity 0.5s linear
    position: absolute
    top: -1000px
    &.active
      visibility: visible
      opacity: 1
      position: static
  .table-fixed tbody
    height: 230px
    overflow-y: overlay
    width: 100%

  .table-fixed thead,
  .table-fixed tbody,
  .table-fixed tr,
  .table-fixed td,
  .table-fixed th
    display: block

  .table-fixed td
    padding: .5rem 0.75rem
    border-top: 0
    font-size: 1rem
    color: $om-gray-700

  .table thead th
    border-top: 0
    border-bottom-width: 1px
    color: $om-gray-700
    font-size: 0.85rem

  .table-fixed tbody td,
  .table-fixed tbody th,
  .table-fixed thead > tr > th
    float: left
    position: relative

    &::after
      content: ''
      clear: both
      display: block

  .campaign-alerts-modal
    .brand-modal-footer-content
      margin-top: 2rem
    small
      font-size: 85%
      display: block

    .alert-description
      color: $om-gray-700
    .alert-how-to-fix
      color: $om-gray-600
    .brand-modal .brand-modal-header
      padding: 1.1rem 2.85rem
      font-size: 1.3rem
      border-bottom: 1px solid $om-gray-300

    .brand-modal .brand-modal-body
      padding: 0.875rem 2.85rem 0 2.85rem

    .alert-name
      font-weight: 500
      color: $om-gray-800
      margin-left: 00.85rem
      line-height: 24px

    .alert-col
      display: flex
      align-content: center

    .col-with-padding
      padding-left: 2rem

  .campaign-alerts-modal-campaign-name
    color: $om-gray-700
    font-weight: 500

  .how-to-install-list
    font-size: 85%
</style>
