<template lang="pug">
div.integration-error-indicator
  img.integration-error-icon(:src="require('../assets/admin/svg/integration-error-icon.svg')")
  span(v-if="!small").integration-error-text {{ $t('integrationError') }}
</template>
<script>
  export default {
    props: {
      small: { type: Boolean, default: false },
    },
  };
</script>

<style lang="sass">
  .integration-error-indicator
    color: #FFA700
    font-size: 12px
    display: flex
    justify-content: flex-end
    align-items: center

    .integration-error-icon
      margin-right: 6px

    .integration-error-text
      white-space: nowrap
      text-decoration: none
</style>
