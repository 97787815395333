import { lsStore, lsRetrieve } from '@/helpers/localStorage';
import { get as _get } from 'lodash-es';
import { onlyNumberOperators } from '@/components/CampaignSettings/Condition/statics';
import CREATE_MATERIALIZED_VIEW from '@/graphql/CreateMaterializedView.gql';
import UPSERT_GOAL from '@/graphql/UpsertGoal.gql';
import { mapGetters } from 'vuex';
import CAMPAIGN_LIST from '@/graphql/CampaignList.gql';
import REFRESH_MATERIALIZED_VIEW from '@/graphql/RefreshGoalsMaterializedView.gql';
import UPSERT_PRIMARY_GOAL from '@/graphql/UpsertPrimaryGoal.gql';

export default {
  data() {
    const defaultGoal = { _id: 'defaultGoal', name: this.$t('conversionGoals.defaultGoal') };
    const DEFAULT_GOAL_BY_CAMPAIGN_KEY = 'defaultGoalsPerCampaign';
    const defaultEventOptions = [
      {
        key: 'pageView',
        value: 'pageView',
        fields: [
          {
            key: 'url',
            value: 'url',
            operators: [
              'equals',
              'notEquals',
              'contains',
              'notContains',
              'startsWith',
              'notStartsWith',
              'endsWith',
              'notEndsWith',
            ],
          },
        ],
      },
      {
        key: 'campaignConversion',
        value: 'eof',
        fields: [
          {
            key: 'campaignId',
            value: 'campaignId',
            operators: ['equals'],
          },
        ],
      },
      {
        key: 'campaignImpression',
        value: 'eoi',
        fields: [
          {
            key: 'campaignId',
            value: 'campaignId',
            operators: ['equals'],
          },
        ],
      },
      {
        key: 'campaignClosed',
        value: 'eoc',
        fields: [
          {
            key: 'campaignId',
            value: 'campaignId',
            operators: ['equals'],
          },
        ],
      },
      {
        key: 'custom',
        value: 'custom',
        operators: [
          'equals',
          'contains',
          'startsWith',
          'endsWith',
          'greaterThan',
          'greaterThanEquals',
          'lessThanEquals',
          'lessThan',
          'interval',
        ],
        fields: [],
      },
      {
        key: 'purchase',
        value: 'eoo',
        fields: [
          {
            key: 'salesValue',
            value: 'total',
            operators: onlyNumberOperators,
          },
          {
            key: 'totalNumberOfProducts',
            value: 'itemCount',
            operators: onlyNumberOperators,
          },
        ],
      },
    ];
    const shopifyAndShoprenterEventOptions = [
      {
        key: 'addToCart',
        value: 'addToCart',
        fields: [
          {
            key: 'price',
            value: 'price',
            operators: onlyNumberOperators,
          },
          {
            key: 'quantity',
            value: 'quantity',
            operators: onlyNumberOperators,
          },
          {
            key: 'cartTotal',
            value: 'cartTotal',
            operators: onlyNumberOperators,
          },
        ],
      },
    ];
    return {
      campaignList: [],
      campaignEvents: ['eof', 'eoc', 'eoi'],
      fieldEvents: ['eoo', 'addToCart'],
      defaultEventOptions,
      shopifyAndShoprenterEventOptions,
      events: [...defaultEventOptions, ...shopifyAndShoprenterEventOptions],
      defaultGoal,
      selectedGoal: null,
      GOAL_TTL: 1000 * 60 * 10,
      DEFAULT_GOAL_BY_CAMPAIGN_KEY,
      customEventPrefix: 'custom:',
      customEventType: 'custom',
      onlyNumberOperators,
    };
  },
  computed: {
    ...mapGetters(['accountFeatures', 'isShoprenterDomain', 'isShopifyDomain']),
    shouldShowDefineGoalButton() {
      return (
        this.isDynamicContent &&
        !this.isShopifyDomain(this.campaign.domain) &&
        !this.isShoprenterDomain(this.campaign.domain) &&
        !this.goals.length
      );
    },
    allGoals: {
      get() {
        if (this.goals.length) {
          return this.goals.map((goal) => {
            if (goal.default) {
              return {
                ...goal,
                name: this.$t(`conversionGoals.eventTypes.${goal.name}`),
              };
            }
            return goal;
          });
        }
        if (this.isDynamicContent) return [];
        return [this.defaultGoal];
      },
    },
    campaignID() {
      return this.$route.params.id;
    },
    isDefaultGoal() {
      return this.selectedGoal?._id === 'defaultGoal';
    },
  },
  methods: {
    async fetchCampaigns(domainIds) {
      if (!this.selectedDomain && !domainIds) return [];
      try {
        const { data } = await this.$apollo.query({
          query: CAMPAIGN_LIST,
          variables: {
            status: true,

            filter: {
              domainIds: domainIds || [this.selectedDomain.key],
            },
          },
        });

        this.campaignList = _get(data, 'campaignList', [])?.filter(
          (campaign) => campaign.status !== 'archived',
        );
      } catch (e) {
        console.error(e);
      }
    },
    saveDefaultGoalToLS(goalId, id, goalLSKey) {
      let currentDefaults = lsRetrieve(goalLSKey);

      if (currentDefaults?.[id]) {
        currentDefaults[id] = goalId;
      } else {
        currentDefaults = {
          ...currentDefaults,
          [id]: goalId,
        };
      }
      lsStore(goalLSKey, currentDefaults);
    },
    getDefaultGoalFromLS(id, goalLSKey) {
      const defaults = lsRetrieve(goalLSKey);
      return defaults?.[id] || null;
    },
    getCustomEventName(type) {
      const customEventMatch = type.match(
        new RegExp(`^(${this.customEventPrefix})(?<event>[a-zA-Z0-9_]*)$`),
      );
      return customEventMatch?.groups?.event ?? null;
    },
    async createMaterializedViewIfNecessary(chosenGoal, goals, domainId) {
      // if default is chosen and there is no other goal yet,
      // we need to check if materialised view exists, if not, create it
      const customGoals = goals.filter((goal) => !goal.default && goal._id !== 'defaultGoal');
      const isChosenDefault = goals.find((goal) => goal._id === chosenGoal)?.default;
      if (isChosenDefault && !customGoals.length) {
        try {
          await this.$apollo.mutate({
            mutation: CREATE_MATERIALIZED_VIEW,
            variables: {
              domainId,
            },
          });
        } catch (_) {
          // err
        }
      }
    },
    openCreateGoalModal(domainId, { isPrimary = false } = {}) {
      this.$modal.show('create-edit-goal', {
        ...(domainId ? { domainId } : {}),
        isPrimary,
      });
    },
    async upsertPrimaryGoal(goalId) {
      await this.$apollo.mutate({
        mutation: UPSERT_PRIMARY_GOAL,
        variables: { campaignId: parseInt(this.campaignID, 10), goalId },
      });
    },
    async upsertGoal(goal) {
      const { _id, name, domainId, rules, isPrimary = false } = goal;
      try {
        const {
          data: {
            result: { success, goalId },
          },
        } = await this.$apollo.mutate({
          mutation: UPSERT_GOAL,
          variables: {
            goal: {
              _id,
              name,
              domainId,
              rules,
            },
          },
        });

        if (success) {
          this.$notify({
            type: 'success',
            text: this.$t('conversionGoals.goalSaved'),
          });
          if (isPrimary) {
            await this.upsertPrimaryGoal(goalId);
          }
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          text: this.$t('conversionGoals.failedToSaveGoal'),
        });
      }
      await this.getGoals();
    },
    setSelectedGoalToNew() {
      const orderedGoals = this.goals.sort((a, b) => new Date(a) > new Date(b));
      this.selectedGoal = orderedGoals[0];
    },
    goalNotInLocalCache(goalId) {
      return !this.goalConversions[goalId] || this.goalConversions[goalId].expire < Date.now();
    },
    async refreshGoalsMaterialisedView(domainId) {
      if (!domainId) return;
      await this.$apollo.mutate({
        mutation: REFRESH_MATERIALIZED_VIEW,
        variables: { domainId },
      });
    },
  },
};
